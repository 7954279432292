$sidebarwidth: 200px;
$sidebarHeaderHeight: 3rem;

.visite-header {
    .visite-toolbar {
        column-gap: 1rem;
        padding: 0 1rem;
    }
    .cles-container {
        display: flex;
        column-gap: .5rem;
        .dropdown-toggle {
            padding: 0.1rem 0.5rem;
            background-color: $bleu;
            border-color: $bleu;
            display: flex;
            align-items: center;
            column-gap: 0.25rem;
        }
        .dropdown-item {
            // color: $bleu;
            &.active {
                background-color: $bleu;
                color: $blanc;
            }
        }
    }
}

.visites-content-body {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    grid-template-rows: 42px auto;
}

.visites-list-header {
    .sort-btn {
        cursor: pointer;
        &.active {
            background-color: rgba($noir, .075);
        }
    }
}

.content-visite {
    display: flex;
    & > div {
        height: 100%;
    }
    &.hide-scrollbar {
        & > div {
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    .sidebar {
        width: 0;
        min-width: 0;
        position: relative;
        overflow: visible;
        z-index: 10;
        transition: width .25s ease-in-out, min-width .25s ease-in-out;
    }
    .sidebar-header {
        position: relative;
        display: flex;
        height: $sidebarHeaderHeight;
        align-items: center;
        padding-left: 2rem;
    }
    .sidebar-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: auto;
        &.hide-scrollbar {
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    .sidebar-toggler {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(-55%, -50%, 0);
        width: 2rem;
        height: 2rem;
        background-color: $blanc;
        border-radius: 1.5rem;
        box-shadow: 0 0 .5rem rgba(0,0,0,.3);
    }
    .visit-content {
        scroll-behavior: smooth;
        flex-grow: 1;
        position: relative;
        z-index: 5;
        overflow-y: auto;
    }
    .accordion-button {
        padding: .5rem 1rem;
        &:not(.collapsed){
            background-color: lighten($noir, 95%);
            color: $noir;
        }
        &:focus {
            box-shadow: none;
        }
        .actions {
            display: flex;
        }
        .btn-group {
            border-radius: .25rem;
            border: 1px solid lighten($noir, 85%);
        }
        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .commentaire-controls {
        a {
            text-decoration: none;
        }
    }
    .sidebar-sommaire {
        .sidebar-toggler {
            left: auto;
            right: 0;
            top: 0;
            transform: translate3d(55%, 30%, 0);
            z-index: 10;
        }
        .list-group-item {
            cursor: pointer;
            padding: .5rem;
            &:hover,
            &.active {
                color: $noir;
                background-color: lighten($bleu, 57%);
                border-color: rgba($noir, 0.125);
            }
            .next-level-count {
                display: inline-flex;
                background-color: lighten($brun, 20%);
                border-radius: 999px;
                color: $blanc;
                padding: 0 .35rem 0 .25rem;
                font-size: .75rem;
                font-weight: bold;
                &:before {
                    content: "\2197";
                    margin-right: 2px;
                }
            }
        }
    }
    .sidebar-themes {
        .sidebar-content {
            top: $sidebarHeaderHeight;
        }
    }
    &.themes-open {
        .sidebar-themes {
            width: $sidebarwidth;
            min-width: $sidebarwidth;
        }
    }
    &.sommaire-open {
        .sidebar-sommaire {
            width: $sidebarwidth;
            min-width: $sidebarwidth;
        }
    }
}

.visite-title {
    column-gap: 1rem;
    align-items: center;
}

.visites-select {
    min-width: 150px;
    appearance: none;
    -webkit-appearance: none;
}

.form-check-input {
    appearance: none;
    -webkit-appearance: none;
}

.form-switch {
    .form-check-input {
        appearance: none;
        -webkit-appearance: none;
    }
}

.visite-sommaire {
    .title {
        padding: .25rem 0 0;
        line-height: 1.2em;
        column-gap: .5rem;
        font-size: .875rem;
        grid-template-columns: 30px auto auto;
        .badge {
            margin-top: -.25rem;
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: normal;
            min-height: 1.5rem;
            min-width: 1.5rem;
            padding: 0 .5rem;
        }
    }
    .item-badge {
        width: 30px;
        line-height: 0;
    }
}

.cles-counter {
    display: flex;
    column-gap: .25rem;
    align-items: center;
    background-color: $bleu;
    color: $blanc;
    border-radius: 999px;
    padding: 0 .25rem;
    i, span {
        display: block;
        height: 100%;
        text-align: center;
    }
    i {
        font-style: normal;
        border-radius: 999px;
        width: 1rem;
        text-align: center;
        cursor: pointer;
    }
}

.visite-toolbar {
    .btn.btn-lock {
        &:disabled {
            opacity: .35;
        }
    }
}

.visites-date-picker {
    .react-daterange-picker__wrapper {
        padding: .8rem;
        border-color: #ced4da;
        border-radius: .25rem;
    }
    .react-daterange-picker__clear-button,
    .react-daterange-picker__inputGroup,
    .react-daterange-picker__range-divider {
        display: none;
    }
    &.clear {
        .react-daterange-picker__inputGroup,
        .react-daterange-picker__clear-button {
            display: block;
        }
    }
}

.visite-read {
    .accordion {
        .list-group {
            & + h2.list-group-item,
            & + h3.list-group-item {
                border-top: 1px solid rgba(0, 0, 0, 0.125);
                margin-top: -1px;
            }
            &.radio,
            &.radiogroup {
                &:last-child {
                    &.border-bottom {
                        margin-bottom: -1px;
                    }
                }
            }
            .list-group-item {
                .form-check-input {
                    appearance: none;
                    -webkit-appearance: none;
                    &:disabled {
                        opacity: .75;
                        & ~ .form-check-label {
                            opacity: .75;
                        }
                    }
                }
            }
        }
    }
    .card-themes {
        .list-group-item {
            cursor: pointer;
        }
    }
}