@media print {
    .app-container {
        display: block;
        overflow: auto;
        height: auto;
    }
    .app-header {
        height: 1.5cm;
        nav,
        .brand a + a,
        div + div {
            display: none !important;
        }
    }
    .page-content {
        overflow: auto;
        .visite-read {
            position: static;
        }
    }
}