@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto-Regular'), url("../fonts/Roboto-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Roboto-Bold.ttf") format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/RobotoCondensed-Bold.ttf") format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/RobotoCondensed-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Quicksand-Medium'), url("../fonts/Quicksand-Medium.ttf") format('truetype');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Quicksand-Bold'), url("../fonts/Quicksand-Bold.ttf") format('truetype');
}


$font-normal: 'Roboto', sans-serif;
$font-condensed: 'Roboto Condensed', sans-serif;
$font-title: 'Quicksand', sans-serif;

html,
body {
    font-family: $font-normal;
}