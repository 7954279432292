:root {
    --bleu: #008cd2;
}
/* COULEURS */

$noir: #000;
$blanc: #fff;

$bleu: #008CD2;
$orange: #CD5C34;
$brun: #877877;
$rose: #D2694E;
$dark: #493E3C;

/* LAYOUT */

$sidebar-width: 240px;