.app-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px calc(100vh - 50px);
    overflow: hidden;
    height: 100vh;
    color: $dark;
}

.app-header,
.app-body {
    grid-column-start: 1;
    grid-column-end: 3;
}

.app-body {
    grid-row-start: 2;
}

.sidebar {
    box-shadow: 0 0 .5rem rgba($noir, .3);
}

.page-content {
    position: relative;
    overflow: hidden;
    height: 100%;
    display: grid;
    grid-template-rows: minmax(min-content, max-content);
}

.content-header {
    width: 100%;
    position: relative;
    z-index: 10;
    padding: 1rem 0;
    h1 {
        font-size: 2rem;
        font-family: $font-title;
        color: $orange;
        margin: 0;
    }
}

.content-body {
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    z-index: 5;
    &.biens-content-body {
        overflow-y: hidden;
    }
    .liste-biens-header {
        display: grid;
        grid-template-columns: minmax(0, 2fr) minmax(0, 0.45fr) minmax(0, 0.7fr) minmax(0, 0.75fr) minmax(0, 1fr) minmax(0, 1fr);
        div {
            padding: .5rem;
            &.active {
                background-color: #ddd;
            }
        }
    }
    .liste-biens-container {
        height: 100%;
    }
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.visite-read {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-rows: 72px auto;
    .content-body {
        padding: 1rem;
    }
}