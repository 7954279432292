a {
    text-decoration: none;
    color: $orange;
}

.overflow-y {
    overflow-y: auto;
}

.list-group-item {
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.tooltip {
    z-index: 1000;
}