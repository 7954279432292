.searchInputGroup {
    position: relative;
}

.searchInputGroup button {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #999;
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    background-size: .75rem;
}