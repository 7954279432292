.location-item {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 0.45fr) minmax(0, 0.7fr) minmax(0, 0.75fr) minmax(0, 1fr) minmax(0, 1fr);
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 1.25em;
    border-bottom: 1px solid #dee2e6;
    height: 100%;
    & > div {
        padding: .5rem;
        &.location-date_visite {
            width: 100px;
        }
    }
    .location-status {
        .badge {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    &.loading {
        opacity: .5;
    }
}

.modal-body {
    .location-item {
        grid-template-columns: minmax(0, 1fr) minmax(0, 0.45fr) minmax(0, 0.7fr) minmax(0, 0.75fr) minmax(0, 1fr) minmax(0, 1fr);
    }
}
